ver = 1.06;

import Swup from "swup";
import SwupA11yPlugin from "@swup/a11y-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupGaPlugin from "@swup/ga-plugin";
import SwupGtmPlugin from "@swup/gtm-plugin";
//import Lenis from "lenis";

import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import Swiper from "swiper";
import {
	Navigation,
	Pagination,
	Autoplay,
	FreeMode,
	A11y,
	EffectFade,
} from "swiper/modules";
import "swiper/swiper-bundle.min.css";

gsap.registerPlugin(ScrollTrigger);

/* Init Swup */
let swup = new Swup({
	animateHistoryBrowsing: true,
	plugins: [
		new SwupPreloadPlugin(),
		new SwupA11yPlugin(),
		new SwupGtmPlugin(),
		new SwupGaPlugin({
			gaMeasurementId: "G-JVYZQ94BVM",
		}),
	],
});

/* Swup Hooks */
swup.hooks.on("page:view", (visit) => {
	if (visit.containers.includes("#swup")) {
		main();
		loadForms();
	} else {
		console.log("another container"); // only used when using SwupJS Fragment
	}
});

function loadForms() {
	/* Init forms */
	const $form = jQuery(".wpcf7-form");
	if ($form.length > 0) {
		wpcf7.init($form[0]);
		wpcf7cf.initForm($form);
	}
}

/* Preloader */
function preloader() {
	function moreThanTwelveHoursOld(timestamp) {
		const twelveHours = 1 * 60 * 60 * 1000; // milliseconds in hour
		const now = new Date().getTime(); // current time in milliseconds
		return now - timestamp > twelveHours;
	}

	const firstVisitInfo = localStorage.getItem("first_visit_info");
	const visitData = firstVisitInfo ? JSON.parse(firstVisitInfo) : null;
	const preloader_element = document.getElementById("preloader");
	const transitionAnimation = document.getElementById("pre__lottie");

	function removePreloader() {
		main(); // Retrigger main();
		preloader_element.classList.add("fade-out");
		preloader_element.addEventListener("transitionend", function () {
			preloader_element.remove();
		});
		/* Set visit time */
		const newVisitData = {
			timestamp: new Date().getTime(),
		};
		localStorage.setItem("first_visit_info", JSON.stringify(newVisitData));
	}

	if (visitData && !moreThanTwelveHoursOld(visitData.timestamp)) {
		document.addEventListener("DOMContentLoaded", function () {
			removePreloader();
		});
	} else {
		document.addEventListener("DOMContentLoaded", function () {
			/* Lottie animation on first visit */
			/* 				setTimeout(() => {
					transitionAnimation.play();
					transitionAnimation.addEventListener('complete', function() {
						removePreloader();
					});
				}, 200); */
			removePreloader();
		});
	}
}
preloader();

/* Skiplink */
document.querySelector(".skipLink").addEventListener("click", handleSkipLink);
document.querySelector(".skipLink").addEventListener("keydown", function (e) {
	if (e.key === "Enter" || e.key === " ") {
		handleSkipLink(e);
	}
});
function handleSkipLink(e) {
	var target = document.getElementById("swup");
	target.setAttribute("tabindex", "-1");
	target.focus();
}

/* Lenis scroll Standard implementation */
/* const lenis = new Lenis({
	wrapper: document.body,
	wheelEventsTarget: document.body,
	duration: 1.4,
});

const logoBase = document.getElementById("BP-logo-base");
const logoMaskPath = document.getElementById("maskShape");

lenis.on("scroll", ({scroll, animatedScroll}) => {
	if (window.getComputedStyle(logoBase).display !== "none") {
		const logoBaseWidth = logoBase.getBoundingClientRect().width;
		const logoBaseScaling = 481 / logoBaseWidth; // 481 is the original SVG dimensions
		let adjustedScrollY = animatedScroll * logoBaseScaling; // Adjust translation based on scaling

		adjustedScrollY = Math.max(0, Math.min(adjustedScrollY, 600));

		logoMaskPath.setAttribute(
			"transform",
			`translate(206, ${adjustedScrollY})`
		);
	}
});

function raf(time) {
	lenis.raf(time);
	requestAnimationFrame(raf);
}

requestAnimationFrame(raf);
lenis.on("scroll", ScrollTrigger.update);
gsap.ticker.add((time) => {
	lenis.raf(time * 1000);
});
gsap.ticker.lagSmoothing(0); */

const logoBase = document.getElementById("BP-logo-base");
const logoMaskPath = document.getElementById("maskShape");

// Use standard scroll event
window.addEventListener("scroll", () => {
	if (window.getComputedStyle(logoBase).display !== "none") {
		const logoBaseWidth = logoBase.getBoundingClientRect().width;
		const logoBaseScaling = 481 / logoBaseWidth; // 481 is the original SVG dimensions

		// Use window.scrollY instead of Lenis' animatedScroll
		let adjustedScrollY = window.scrollY * logoBaseScaling;

		// Clamp the value between 0 and 600
		adjustedScrollY = Math.max(0, Math.min(adjustedScrollY, 600));

		logoMaskPath.setAttribute(
			"transform",
			`translate(206, ${adjustedScrollY})`
		);
	}
});

function toggleHamburger() {
	const hamburger = document.getElementById("hamburger");
	const takeoverLinks = document.querySelectorAll("#menuTakeover a");
	const navbarLinks = document.querySelectorAll("header a");
	const header = document.querySelector("header");
	const links = [...takeoverLinks, ...navbarLinks];

	const takeoverMenu = document.getElementById("menuTakeover");

	/* Clicking menu */
	hamburger.addEventListener("click", function () {
		if (takeoverMenu.classList.contains("open")) {
			hamburger.classList.remove("active");
			header.classList.remove("active");
			closeMenu();
		} else {
			hamburger.classList.add("active");
			header.classList.add("active");
			openMenu();
		}
	});

	/* Clicking links inside takeover */
	links.forEach(function (link) {
		link.addEventListener("click", closeMenu);
	});

	/* Open menu */
	function openMenu() {
		//lenis.stop();
		takeoverMenu.classList.add("open");
		document.body.classList.add("overflow");

		/* Whole takover wrapper */
		gsap.to(takeoverMenu, {
			// TO
			opacity: 1,
			duration: 0.6,
			ease: "power1.out",
		});

		/* Main links */
		gsap.from(".links-primary .menu-item", {
			// FROM
			opacity: 0,
			x: "-1rem",
			stagger: 0.1,
			duration: 0.5,
			ease: "power1.out",
		});

		/* Small link */
		gsap.from(".links-secondary .menu-item", {
			// FROM
			opacity: 0,
			x: "-0.5rem",
			stagger: 0.05,
			duration: 0.5,
			delay: 0.4,
			ease: "power1.out",
		});

		/* Large links media wrapper */
		gsap.fromTo(
			".hover-media-wrapper",
			{
				// FROM
				clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
			},
			{
				clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 100%)",
				duration: 1.2,
				ease: "power3.out",
			}
		);

		gsap.from(".hover-media-wrapper picture", {
			// FROM
			scale: 1.15,
			duration: 0.8,
			ease: "power1.out",
		});
	}

	/* Close menu */
	function closeMenu() {
		//lenis.start();
		takeoverMenu.classList.remove("open");
		hamburger.classList.remove("active");
		document.body.classList.remove("overflow");

		gsap.to(takeoverMenu, {
			// TO
			opacity: 0,
			duration: 0.5,
			ease: "power1.out",
		});
	}
}
toggleHamburger();

function hoverHamburgerLinks() {
	// Select all menu items and hover-media elements
	const menuItems = document.querySelectorAll(".menu-item");
	const hoverMedias = document.querySelectorAll(".hover-media");

	if (hoverMedias) {
		let activeMedia = hoverMedias[0];
		gsap.set(activeMedia, {zIndex: 2});

		menuItems.forEach((item) => {
			item.addEventListener("mouseenter", () => {
				const hoverValue = item.getAttribute("data-menu-hover");
				const matchedMedia = document.querySelector(
					`.hover-media[data-menu-hover="${hoverValue}"]`
				);

				// Set all other hover-medias to zIndex 1
				hoverMedias.forEach((media) => {
					gsap.set(media, {zIndex: 1});
				});

				if (matchedMedia && matchedMedia !== activeMedia) {
					const pictureElement =
						matchedMedia.querySelector("picture");

					gsap.set(activeMedia, {zIndex: 2});
					gsap.set(matchedMedia, {zIndex: 10});

					gsap.fromTo(
						matchedMedia,
						{
							clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
						},
						{
							clipPath:
								"polygon(0 0, 100% 0%, 100% 100%, 0 100%)",
							duration: 1.2,
							ease: "power3.out",
						}
					);
					gsap.from(pictureElement, {
						scale: 1.15,
						duration: 0.8,
						ease: "power1.out",
					});

					activeMedia = matchedMedia; // Update the active media
				} else {
					gsap.set(activeMedia, {zIndex: 10});
				}
			});
		});
	}
}

hoverHamburgerLinks();

/* Main function , runs on load and on every page visit -> look at line 62 for swup hooks */
function main() {
	ScrollTrigger.getAll().forEach((st) => st.kill());
	//swiper.destroy(true, true) // FIX target every slide function

	/* Load videos after swup transition*/
	function loadVideos() {
		const videos = document.querySelectorAll("video");
		videos.forEach((video) => {
			video.load();
		});
	}
	loadVideos();

	/* Embeded script reload */
	function reloadEmbedScripts() {
		/* 		document.querySelectorAll('script[data-reload]').forEach(script => {
			script.remove();
		}); */

		document.querySelectorAll("script[data-reload]").forEach((script) => {
			if (script.type === "text/javascript" || script.type === "") {
				//console.log("script found");
				// Create a new script element
				const newScript = document.createElement("script");
				// Copy attributes
				if (script.src) {
					newScript.src = script.src;
				}
				if (script.async) {
					newScript.async = script.async;
				}
				// Copy inline script content
				if (script.innerHTML) {
					newScript.innerHTML = script.innerHTML;
				}
				// Replace the old script with the new one
				script.replaceWith(newScript);
			}
		});
	}
	reloadEmbedScripts();

	/* Untapped score converter */
	function updateRatings() {
		const ratingElements = document.querySelectorAll(".rating.small");

		ratingElements.forEach(function (element) {
			const classes = element.className.split(" ");
			const ratingClass = classes.find(function (c) {
				return /^r\d{3}$/.test(c);
			});

			if (ratingClass) {
				const ratingValue = ratingClass.slice(1);
				const ratingText = (ratingValue / 100).toFixed(2);
				element.textContent = ratingText;
			}
		});
	}
	const beerListObserver = new MutationObserver(function (mutations) {
		mutations.forEach(function (mutation) {
			if (mutation.addedNodes.length) {
				updateRatings();
			}
		});
	});
	const beerListContainer = document.querySelector("#menu-container");
	if (beerListContainer) {
		beerListObserver.observe(beerListContainer, {
			childList: true,
			subtree: true,
		});
	}

	/* Booking Modal open */
	function bookingOpenModals() {
		const bookingButtons = document.querySelectorAll(".button__booking");
		const modals = document.querySelectorAll(".modal");

		function showModal(event) {
			const button = event.currentTarget;
			const modalData = button.getAttribute("data-modal");
			const modal = document.querySelector(`[data-modal="${modalData}"]`);
			if (modal) {
				modal.classList.toggle("modal-visable");
				document.addEventListener("keydown", handleEscapeKey); // Add event listener for Esc key
				// reloadEmbedScripts(); Not sure if this is needed?
				//lenis.stop();
			}
		}

		bookingButtons.forEach((button) => {
			button.addEventListener("click", showModal);
		});

		function closeModal(modal) {
			modal.classList.remove("modal-visable");
			document.removeEventListener("keydown", handleEscapeKey); // Remove event listener when modal is closed
			//lenis.start();
		}

		function handleEscapeKey(event) {
			if (event.key === "Escape") {
				const visibleModal = document.querySelector(".modal-visable");
				if (visibleModal) {
					closeModal(visibleModal);
				}
			}
		}

		function handleBackdropClick(event) {
			const modal = event.currentTarget.closest(".modal");
			if (modal && event.target.classList.contains("backdrop-filter")) {
				closeModal(modal);
			}
		}

		modals.forEach((modal) => {
			const closeButton = modal.querySelector(".button__close");
			if (closeButton) {
				closeButton.addEventListener("click", () => closeModal(modal));
			}
			const backdrop = modal.querySelector(".backdrop-filter");
			if (backdrop) {
				backdrop.addEventListener("click", handleBackdropClick);
			}
		});
	}
	bookingOpenModals();

	function bookingBlockDates() {
		document
			.querySelectorAll('input[type="date"]')
			.forEach(function (dateField) {
				const className = Array.from(dateField.classList).find((cls) =>
					cls.startsWith("date-block_")
				);
				if (className) {
					const blockedDays = className
						.split("_")[1]
						.split("")
						.map(Number);
					const alertMessage =
						"önskat datum matchar inte med dina tidigare val av veckodag, vänligen välj annat datum";

					let selectedDate = null;

					// Store the selected date without validation
					dateField.addEventListener("change", function (e) {
						selectedDate = new Date(e.target.value);

						// Clear all other date fields
						document
							.querySelectorAll('input[type="date"]')
							.forEach(function (otherDateField) {
								if (otherDateField !== dateField) {
									otherDateField.value = "";
								}
							});

						// Clear all checkboxes
						document
							.querySelectorAll(".date-block-checker")
							.forEach(function (checkbox) {
								checkbox.checked = false;
							});
					});

					// Validate the stored date when a checkbox is clicked
					document
						.querySelectorAll(".date-block-checker")
						.forEach(function (checkbox) {
							checkbox.addEventListener("change", function () {
								if (selectedDate) {
									const day = selectedDate.getUTCDay();

									// Check if the selected day is in the blockedDays array
									if (blockedDays.includes(day)) {
										dateField.value = ""; // Clear the input field
										checkbox.checked = false; // Clear the checkbox
										alert(alertMessage);
									}
								}
							});
						});
				}
			});
	}
	bookingBlockDates();

	/* Accordion functionality */
	document.querySelectorAll(".accordion-row").forEach((accordionRow) => {
		const heading = accordionRow.firstElementChild;
		const content = accordionRow.lastElementChild;

		// Ensure the heading can receive focus
		heading.tabIndex = heading.tabIndex >= 0 ? heading.tabIndex : 0;

		// Check if the accordion row is active on page load
		if (accordionRow.classList.contains("active")) {
			content.style.maxHeight = content.scrollHeight + "px";
			heading.classList.add("active"); // Also add 'active' class to heading
		}

		// Toggle accordion on click
		heading.addEventListener("click", () => {
			const isActive = accordionRow.classList.toggle("active");
			heading.classList.toggle("active", isActive);
			content.style.maxHeight = isActive
				? content.scrollHeight + "px"
				: null;
		});

		// Toggle accordion on Enter key press
		heading.addEventListener("keydown", (e) => {
			if (e.key === "Enter" || e.keyCode === 13) {
				heading.click();
			}
		});
	});

	/* Toggle open newsletter section */
	function openNewsletterSection() {
		const newsletterWrapper = document.querySelector(".newsletter-wrapper");
		const formWrapper = newsletterWrapper
			? newsletterWrapper.querySelector(".form-wrapper")
			: null;

		if (newsletterWrapper && formWrapper) {
			function animateHeight(isOpening) {
				if (isOpening) {
					formWrapper.style.maxHeight =
						formWrapper.scrollHeight + "px";
				} else {
					formWrapper.style.maxHeight = "0px";
				}
			}

			newsletterWrapper.addEventListener("click", function () {
				const isOpen = newsletterWrapper.classList.toggle("open");
				animateHeight(isOpen);
			});

			newsletterWrapper.addEventListener("keydown", function (event) {
				if (event.key === "Enter") {
					const isOpen = newsletterWrapper.classList.toggle("open");
					animateHeight(isOpen);
				}
			});
		}
	}
	openNewsletterSection();

	function handleLazyLoad(config = {lazy: true, timeout: 0.1}) {
		let lazyImages = gsap.utils.toArray("img[loading='lazy']");
		let imgLoaded = 0;

		const onImgLoad = () => {
			imgLoaded++;
			if (imgLoaded === lazyImages.length) {
				ScrollTrigger.refresh();
			}
		};

		lazyImages.forEach((img) => {
			if (config.lazy === false) {
				img.loading = "eager";
			}

			if (img.complete) {
				onImgLoad();
			} else {
				img.addEventListener("load", onImgLoad);
			}
		});
	}
	handleLazyLoad({lazy: true, timeout: 0.1}); // Explicit configuration

	setTimeout(function () {
		/* Change background color on page scroll */
		function bodyBG() {
			// Select all sections with a data-color attribute
			const sections = document.querySelectorAll("section[data-color]");
			const body = document.body;

			sections.forEach((section) => {
				// Get the color from the data-color attribute
				const color = section.getAttribute("data-color");

				// Create a ScrollTrigger for each section
				ScrollTrigger.create({
					trigger: section,
					start: "top 80%", // When the top of the section hits the center of the viewport
					end: "bottom 80%", // When the bottom of the section leaves the center of the viewport
					toggleActions: "play none play none",
					//markers: true,
					onEnter: () => {
						body.className = color;
					},
					onEnterBack: () => {
						body.className = color;
					},
					onLeave: () => {},
					onLeaveBack: () => {},
				});
			});
		}
		bodyBG();

		/* Flower spinner */
		if (document.querySelector(".flower")) {
			let flowerImage = gsap.utils.toArray(".flower");

			flowerImage.forEach((section) => {
				let svg = section.querySelector("svg");

				gsap.to(svg, {
					rotateZ: "360deg",
					ease: "none",
					scrollTrigger: {
						trigger: section,
						start: "bottom bottom",
						end: "start start",
						scrub: 1,
						//markers: true
					},
				});
			});
		}

		if (document.querySelector(".menu__manual")) {
			const menuSections = document.querySelectorAll(".menu-section");
			const menuSectionsImages = document.querySelectorAll(
				".menu-media-wrapper > .content-media"
			);
			const firstImage = menuSectionsImages[0];

			// Set all images to zero opacity initially, except the first one
			gsap.set(menuSectionsImages, {opacity: 0});
			gsap.set(firstImage, {opacity: 1});

			menuSections.forEach((section) => {
				const menuId = section.getAttribute("data-menu-id");
				const image = document.querySelector(
					`.content-media[data-menu-id="${menuId}"]`
				);

				if (image) {
					ScrollTrigger.create({
						trigger: section,
						start: "top center",
						end: "bottom center",
						//markers: true,
						toggleActions: "play reverse play reverse",
						onEnter: () => {
							// Animate the opacity with a duration for smooth transitions
							gsap.to(menuSectionsImages, {
								opacity: 0,
								duration: 0.5,
								ease: "power3.out",
							});
							gsap.to(image, {
								opacity: 1,
								duration: 0.5,
								ease: "power3.out",
							});
						},
						/* 						onLeave: () => {
							// Ensure the image fades out when leaving the section
							gsap.to(image, { opacity: 0, duration: 0.5, ease: 'power3.out' });
						}, */
						onEnterBack: () => {
							// Animate the opacity again for smooth transition when scrolling back
							gsap.to(menuSectionsImages, {
								opacity: 0,
								duration: 0.5,
								ease: "power3.out",
							});
							gsap.to(image, {
								opacity: 1,
								duration: 0.5,
								ease: "power3.out",
							});
						},
						/* 						onLeaveBack: () => {
							// Ensure the image fades out when leaving the section while scrolling back
							gsap.to(image, { opacity: 0, duration: 0.5, ease: 'power3.out' });
						} */
					});
				}
			});
		}

		/* Hero offset Paralax */
		if (document.querySelector(".hero-para")) {
			let hero_media = gsap.utils.toArray(".hero-para .para-media");
			let hero_text = gsap.utils.toArray(".hero-para .para-text");

			let hero_tl = gsap.timeline({
				scrollTrigger: {
					trigger: ".hero-para",
					start: "top top",
					end: "bottom",
					scrub: 0,
					//markers: true,
				},
			});

			hero_media.forEach((media) => {
				hero_tl.fromTo(
					media,
					{
						y: 0,
						webkitFilter: "brightness(1)",
						filter: "brightness(1)",
					},
					{
						y: "33vh",
						//ease: 'none',
						webkitFilter: "brightness(0.1)",
						filter: "brightness(0.1)",
					},
					"<"
				); // The "<" ensures that all animations start at the same time
			});

			hero_text.forEach((text) => {
				hero_tl.to(
					text,
					{
						y: "15vh",
						ease: "none",
					},
					"<"
				);
			});
		}

		/* Pin media using gsap. Position:sticky broken becuase of smoothscroll? */
		/* FIX Add image fade effect for sections */
		if (document.querySelector(".menu-media-wrapperr")) {
			gsap.to(".menu-media-wrapper", {
				// duration: 3,
				// y: 850,
				scrollTrigger: {
					trigger: ".menu__manual",
					start: "top top",
					end: "bottom bottom",
					ease: "power2",
					pin: ".menu-media-wrapper",
					//markers: true,
				},
			});
		}
	}, 200); // Delay so all content gets loaded at correct position. Page transition animation was offsetting triggers.

	/* Lazyload auto videos */
	let lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

	if ("IntersectionObserver" in window) {
		let lazyVideoObserver = new IntersectionObserver(function (
			entries,
			observer
		) {
			entries.forEach(function (video) {
				if (video.isIntersecting) {
					for (let source in video.target.children) {
						let videoSource = video.target.children[source];
						if (
							typeof videoSource.tagName === "string" &&
							videoSource.tagName === "SOURCE"
						) {
							videoSource.src = videoSource.dataset.src;
						}
					}
					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function (lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}

	/* SwiperJS Sliders */

	/* Frontpage Hero slider */
	let frontpageHeroSlider = document.querySelector(".swiper-hero-links");

	if (frontpageHeroSlider) {
		const progressBar = document.querySelector(".autoplay-progress > .bar");

		let swiperHeroSlider = new Swiper(".swiper-hero-links", {
			modules: [Autoplay, A11y, EffectFade],
			slidesPerView: 1,
			loop: true,
			effect: "fade",
			fadeEffect: {
				crossFade: true,
			},
			speed: 1000,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			keyboardControl: false,
			grabCursor: false,
			on: {
				slideChangeTransitionStart: function () {
					//console.log('slideChangeTransitionStart');
				},
				slideChangeTransitionEnd: function () {
					//console.log('slideChangeTransitionEnd');
				},
				autoplayTimeLeft(s, time, progress) {
					progressBar.style.setProperty("--progress", 1 - progress);
				},
			},
		});

		const linkImages = document.querySelectorAll(".link-slide");
		const linkBgImage = document.querySelector(".link-bg-image");
		const linkBgSource = linkBgImage.querySelector("source");

		const originalSrcset = linkBgSource.getAttribute("srcset");

		linkImages.forEach((linkImage) => {
			const linkImageSource = linkImage.querySelector("picture source");

			linkImage.addEventListener("mouseover", function () {
				const newSrcset = linkImageSource.getAttribute("srcset");
				linkBgSource.setAttribute("srcset", newSrcset);
				linkBgImage.style.opacity = "1";
			});

			linkImage.addEventListener("mouseout", function () {
				//inkBgSource.setAttribute('srcset', originalSrcset);
				linkBgImage.style.opacity = "0";
			});
		});
	}

	/* Slider Gallery */
	let heroScrollElement = document.querySelector(".swiper-hero-gallery");
	if (heroScrollElement) {
		let slideCount = heroScrollElement.querySelectorAll(".swiper-slide");
		let slidesNum = slideCount.length;

		let swiperHeroGallery = new Swiper(".swiper-hero-gallery", {
			modules: [Autoplay, FreeMode],
			slidesPerView: 2,
			spaceBetween: 0,
			centeredSlides: true,
			loop: true,
			loopedSlides: slidesNum,
			keyboardControl: false,
			grabCursor: false,
			allowTouchMove: false,
			speed: 20000,
			autoplay: {
				delay: 0,
				disableOnInteraction: false,
				pauseOnMouseEnter: false,
			},
		});
	}

	/* FIX ADD FOR EACH SLIDE */
	/* Slider Large Gallery */
	let largeGallery = document.querySelector(".swiper-large-gallery");
	if (largeGallery) {
		let slideCount = largeGallery.querySelectorAll(".swiper-slide");
		let slidesNum = slideCount.length;

		let swiperLargeGallery = new Swiper(".swiper-large-gallery", {
			modules: [Autoplay, EffectFade],
			slidesPerView: 1,
			spaceBetween: 0,
			centeredSlides: true,
			loop: true,
			loopedSlides: slidesNum,
			keyboardControl: true,
			grabCursor: false,
			allowTouchMove: true,
			speed: 500,
			effect: "fade",
			fadeEffect: {
				crossFade: true,
			},
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
				pauseOnMouseEnter: false,
			},
		});
	}

	/* Fade in */
	const fade = gsap.utils.toArray(".content-media.fade");

	fade.forEach((fade) => {
		gsap.from(fade, {
			autoAlpha: 0,
			duration: 1,
			delay: 0,
			ease: "power4.easeOut",
			scrollTrigger: {
				trigger: fade,
				start: "top 95%",
				end: "bottom 5%",
				toggleActions: "play none play none",
			},
		});
	});
}

// Credits
if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
	var args = [
		"%c Design & Developed by Milk \u00A9 https://milk.se \n",
		"padding:10px 0;background: #010101;color: #ffffff;",
	];
	window.console.log.apply(console, args);
} else if (window.console) {
	window.console.log("Design & Developed by Milk :) - https://milk.se");
}
